<template>
  <div class="fenxiang">
    <topbutton router="kuangchi"></topbutton>
    <div class="imgs">
        <div class="i_img">
            <img src="@/assets/imgsnowball/yaoqinghaoyou.png" alt="">
            <div class="line"></div>
            <img src="@/assets/imgsnowball/haoyouqidonwakuang.png" alt="">
            <div class="line"></div>
            <img src="@/assets/imgsnowball/fenxiangshoyi.png" alt="">
        </div>
      
    </div>
    <div class="i_text">
        <div>{{ $t('lang.d139') }}</div>
        <div style="margin-left: 30px;">{{ $t('lang.d140') }}</div>
        <div>{{ $t('lang.d141') }}</div>
       </div>
       <van-tabs animated class="van_tabs">
  <van-tab :title="`${$t('lang.d142')}`">
    <div class="i_lon" v-if="onelist.length ==0">
        <div class="meiyou">
            <img src="@/assets/img/zanwu.png" alt="">
            <span>{{ $t('lang.swap365') }}</span>
        </div>
     </div>
     <div class="tab_con" v-else>
        <div style="font-size: 14px; color: #999;" class="dis">
          <span>{{ $t('lang.d143') }}</span>
          <span>{{ $t('lang.d144') }}</span>
        </div>
          
            <van-list
  v-model="loading1"
  :finished="finished1"
  :finished-text="`${$t('lang.d145')}`"
  @load="onLoad1"
  class="t_ul"
>
<div class="t_li dis" v-for="item in onelist">
              <span style="color: #000; font-size: 14px;">{{ item.address }}</span>
              <span style="color: #000; font-size: 14px;" v-if="item.miner_status ==1">{{item.miner_status_txt}}</span>
              <span style="color: #999999; font-size: 14px;" v-else>{{item.miner_status_txt}}</span>
            </div>
</van-list>
            <!-- <div class="t_li dis" v-for="item in onelist">
              <span style="color: #fff; font-size: 14px;">{{ item.address }}</span>
              <span style="color: #fff; font-size: 14px;" v-if="item.miner_status ==1">{{item.miner_status_txt}}</span>
              <span style="color: #999999; font-size: 14px;" v-else>{{item.miner_status_txt}}</span>
            </div> -->
          
     </div>
  </van-tab>
  <van-tab :title="`${$t('lang.d146')}`">
    <div class="i_lon" v-if="twolist.length ==0">
        <div class="meiyou">
            <img src="@/assets/img/zanwu.png" alt="">
            <span>{{ $t('lang.swap365') }}</span>
        </div>
     </div>
     <div class="tab_con" v-else>
        <div style="font-size: 14px; color: #999;" class="dis">
          <span>{{ $t('lang.d143') }}</span>
          <span>{{ $t('lang.d144') }}</span>
        </div>
        <van-list
  v-model="loading2"
  :finished="finished2"
  :finished-text="`${$t('lang.d145')}`"
  @load="onLoad2"
  class="t_ul"
>
<div class="t_li dis" v-for="item in twolist">
              <span style="color: #000; font-size: 14px;">{{ item.address }}</span>
              <span style="color: #000; font-size: 14px;" v-if="item.miner_status ==1">{{item.miner_status_txt}}</span>
              <span style="color: #999999; font-size: 14px;" v-else>{{item.miner_status_txt}}</span>
            </div>
</van-list>
     </div>
</van-tab>

</van-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { kuangchi } from '@/api/index'
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      page1:1,
      page_size1:10,
      page2:1,
      page_size2:10,
      loading1:false,
      finished1:false,
      isrun1:0,
      loading2:false,
      finished2:false,
      isrun2:0,
      onelist:[
       
      ],
      twolist:[]
    }
  },
  watch: {
        account() {
            this.init();
            // this.finished = false;
        },
        lang() {
            this.init();
          
        },
       
    },
  computed: {
    ...mapState(['account','lang']),
  },
  created() {
   this.init()
  },
  methods: {
          init (){
     this.getonelist()
     this.gettwolist()
          },
          getonelist (){
            let data ={
              page:this.page1,
              page_size:this.page_size1,
              level:1
            }
                kuangchi.getminerwalletrecordlist(data,this.account).then(res =>{
                  console.log(res);
                  this.onelist =res.data.list ||[]
                })
          },
          gettwolist (){
            let data ={
              page:this.page2,
              page_size:this.page_size2,
              level:2
            }
            kuangchi.getminerwalletrecordlist(data,this.account).then(res =>{
                  console.log(res);
                  this.twolist =res.data.list ||[]
                })
          },
          onLoad1() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.isrun1 = this.onelist.length;
        this.page_size1+=10;
       
      let data ={
                page:1,
                page_size:this.page_size1,
               level:1
            }
            kuangchi.getminerwalletrecordlist(data,this.account).then(res =>{
                console.log(res);
                this.onelist =res.data.list ||[]
                 if(this.isrun1 ==this.onelist.length){
                  this.finished1 =true
                 }
            })
        // 加载状态结束
        this.loading1 = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    
    },
    onLoad2() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.isrun2 = this.twolist.length;
        this.page_size2+=10;
       
      let data ={
                page:1,
                page_size:this.page_size2,
               level:2
            }
            kuangchi.getminerwalletrecordlist(data,this.account).then(res =>{
                console.log(res);
                this.twolist =res.data.list ||[]
                 if(this.isrun2 ==this.twolist.length){
                  this.finished2 =true
                 }
            })
        // 加载状态结束
        this.loading2 = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    
    },
  },
}
</script>

<style lang="less" scoped>
.fenxiang{
    width: 100%;
    background: linear-gradient(180deg, rgba(76, 126, 249, 1) 0%, rgba(150, 208, 255, 1) 100%);
   min-height: 100vh;
    box-sizing: border-box;
    padding: 21px;
    padding-top: 0;
}
.imgs{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 15px;
    .i_img{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 42px;
            height: 42px;
        }
        .line{
          border: 2px dotted #ffff;
          width: 50px;
          // height: 1px;
        }
    }
   
}
.i_text{
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, 1);
 font-size: 14px;
 font-weight: 500;
 font-family: "PingFang";
    }
    .van_tabs{
    background: none;
    margin-top: 20px;
}
/deep/.van-tabs__nav {
    background: none!important;
}
/deep/.van-tabs__line{
    background: #fff !important;
    width: 20px;
    height: 2px;
}
/deep/.van-tab {
    
  font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
   }
/deep/.van-tab--active{
    color: #fff;
    font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
}
.i_lon{
        width: 100%;
        height: 261px;
        border-radius: 16px;
opacity: 1;
background: rgba(255, 255, 255, 1);

margin-top: 20px;
// margin-top: 20px;

position: relative;
.meiyou{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    img{
        width: 200px;
    }
    span{
        color: rgba(153, 153, 153, 1);
 font-size: 14px;
 font-weight: 700;
 font-family: "PingFang";
    }
}
}
.tab_con{
  
border-radius: 16px;
opacity: 1;
background: #fff;


margin-top: 20px;
box-sizing: border-box;
padding: 17px 20px;
    .t_ul{
      min-height: 200px;
      max-height: 400px;
      overflow-y: scroll;
      color: #000;
     
    }
}
.dis{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
</style>