export const lang = {
   swap1: 'Exchange',
   swap2: 'Trade tokens in an instant',
   swap3: 'From',
   swap4: 'Balance: ',
   swap5: 'token',
   swap6: 'MAX',
   swap7: 'Price',
   swap8: 'Slippage Tolerance',
   swap9: 'Connect Wallet',
   swap10: 'Enter an amount',
   swap11: 'insufficient balance',
   swap12: 'Insufficient liquidity for this trade',
   swap13: 'Select a token',
   swap14: 'Approve',
   swap15: 'Wrap',
   swap16: 'Unwrap',
   swap17: 'swap',
   swap18: 'Transaction receipt',
   swap19: 'Waiting for confirmation',
   swap20: 'Will',
   swap21: 'Exchange for',
   swap22: 'Confirm this transaction in your wallet',
   swap23: 'Submitted transaction',
   swap24: 'View on BSC',
   swap25: 'Dismiss',
   swap26: 'Transaction rejected',
   swap27: 'Confirm Swap',
   swap28: 'If the output is a valuation, you will get at least {inputOutMin} {symbol}, Or the deal will be withdrawn',
   swap29: 'Enter as valuation, you will sell the most {inputInMax} {symbol}, Or the deal will be withdrawn',
   swap30: 'Minimum received',
   swap31: 'Maximum sales',
   swap32: 'To',
   swap33: 'Manage tokens',
   swap34: 'Import token',
   swap35: 'Import',
   swap36: 'Manage tokens',
   swap37: 'Custom token',
   swap38: 'Clear all',
   swap39: 'Search name or paste address',
   swap40: 'Settings',
   swap41: 'Default transaction speed(GWEI)',
   swap42: 'Standard',
   swap43: 'Fast',
   swap44: 'Instant',
   swap46: 'Enter a vaild slippage percentage',
   swap47: 'Your transaction may fail',
   swap48: 'Your transaction may be frontrun',
   swap49: 'Tx deadline (mins)',
   swap50: 'estimated',
   swap51: 'Disable Multihops',
   swap52: 'Recent Transactions',
   swap53: 'No recent transactions',
   swap54: 'Clear All',
   swap55: 'Not connected',
   swap56: 'Join The Board',
   swap57: 'Donates',
   swap58: 'Rights',
   swap59: 'List Of Board Address',
   swap60: 'Referrals',
   swap61: 'Copy',
   swap62: 'My DAPP Referrer:',
   swap63: 'Referrals Address',
   swap64: 'Something went wrong. Please try again later',
   swap65: 'not open',
   swap66: 'harvest',
   swap67: 'Extraction succeeded',
   swap68: 'Donation in progress, please wait',
   swap69: 'Donated',
   swap70: 'Non-Whitelist',
   swap71: 'Failed',
   swap72: 'Insufficient Balance',
   swap73: 'Waiting',
   swap74: 'privilege grant failed',
   swap75: 'Donation in progress, please wait',
   swap76: 'Failed',
   swap77: 'Swap',
   swap78: 'Liquidity',
   swap79: 'Member',
   swap80: 'Referrals',
   swap81: 'Reward Pool',
   swap82: 'Cross chain bridge',
   swap83: 'Pool',
   swap84: 'Farm',
   swap85: 'Home',
   swap86: 'Markets',
   swap87: 'Swap',
   swap88: 'Swap',
   swap89: 'Earn',
   swap90: 'Confirm',
   swap91: 'Price Impact',
   swap92: 'Minimum received',
   swap93: 'Liquidity Provider Fee',
   swap94: 'Add Liquidity',
   swap95: 'Add liquidity to receive LP tokens',
   swap96: 'Share of Pool',
   swap97: 'Prices and pool share',
   swap98: 'Your Liquidity',
   swap99: 'Remove liquidity to receive tokens back',
   swap100: 'No liquidity found.',
   swap101: 'Dont see a pool you joined?',
   swap102: 'Find other LP tokens',
   swap103: 'Pooled',
   swap104: 'Remove',
   swap105: 'Supply',
   swap106: 'Import Pool',
   swap107: 'Import an existing pool',
   swap108: 'Select a token to find your liquidity.',
   swap109: 'You don’t have liquidity in this pool yet.',
   swap110: 'LP tokens in your wallet',
   swap111: 'To receive',
   swap112: 'and',
   swap113: 'Amount',
   swap114: 'YOU WILL RECEIVE',
   swap115: 'Output is estimated. If the price changes by more than {slippage}% your transaction will revert.',
   swap116: 'Burned',
   swap117: 'Deposited',
   swap118: 'Confirm Supply',
   swap119: 'Rates',
   swap120: 'You are the first liquidity provider.',
   swap121: 'The ratio of tokens you add will set the price of this pool.',
   swap122: 'Once you are happy with the rate click supply to review.',
   swap123: 'Removing ',
   swap124: 'Supplying',
   swap125: 'Confirm supply',
   swap126: 'White Paper',
   swap127: 'Audit',
   swap128: 'Notice',
   swap129: 'Tips',
   swap130: 'PHO 1:1 exchange',
   swap131: 'More',
   swap132: 'Loading',
   swap133: 'Information',
   swap134: 'Donation quota is full',
   swap135: 'MY PHO',
   swap136: 'PHO in Wallet',
   swap137: 'PHO to Harvest',
   swap138: 'PHO Price',
   swap139: 'PHO Total',
   swap140: 'PHO Market capitalization',
   swap141: 'PHO INFO',
   swap142: 'Earned',
   swap143: 'STAKED',
   swap144: 'Personal TVL',
   swap145: 'Community TVL',
   swap146: 'Harvest',
   swap147: 'Total reward ',
   swap148: 'Reward today',
   swap149: 'LP Maket Makers address',
   swap150: 'My invitation link',
   swap151: 'Maximum computing power area',
   swap152: 'Other computing power areas',
   swap153: 'My Friends Referrer',
   swap154: 'Cancel',
   swap155: 'Modify Referrer',
   swap156: 'Invitee cannot be empty',
   swap157: 'Modified Successfully',
   swap158: 'STAKED LP token',
   swap159: 'REDEEM LP token',
   swap160: 'REDEEM',
   swap161: 'Obtain',
   swap162: 'No income at present',
   swap163: 'Route',
   swap164: 'Audit institution',
   swap165: 'Cooperative partner',
   swap166: 'Issue',
   swap167: 'Activity',
   swap168: 'Single pool',
   swap169: 'LP pool',
   swap170: 'Congress',
   swap171: 'Card',
   swap172: 'Blind box',
   swap173: 'Game',
   swap174: 'The total amount allocated by the stake pool',
   swap175: 'Amount to be claimed',
   swap176: 'I have got the amount',
   swap177: 'Extraction',
   swap178: 'Super node',
   swap179: 'Equity card has received share',
   swap180: 'National treasury',
   swap181: 'The accumulated amount of the treasury',
   swap182: 'Amount allocated by the treasury',
   swap183: 'Amount repurchased by the treasury',
   swap184: 'Subgraph Health Indicator',
   swap185: 'D6 Swap is the first equity tax system defi platform in the whole network based on okexchain. It gradually introduces swap, NFT, gamefi, lend, machine gun pool and other modules. Since its launch, it has provided a wider range of asset appreciation schemes and an unprecedented sense of experience through leading technology and fair and open market concept, which is deeply loved by the majority of users.',
   swap186: 'One-click take out',
   swap187: 'price impact too high',
   swap188: 'FAQ',
   swap189: 'NFT',
   swap190: 'Earn coins',
   swap191: 'In progress',
   swap192: 'Has ended',
   swap193: 'Benefit Card',
   swap193: 'Description',
   swap194: 'Ecological equity cards, limited to 1680 in the world',
   swap195: 'The requirements and benefits of lighting up the equity equity ecological card are as follows',
   swan196: 'One star card: the personal address has 1000usdt liquidity, the team has 1000usdt liquidity, and the income is divided into 0.1% of transaction tax',
   swap197: 'Two star card: the individual has 2000usdt liquidity and the team has 10000 usdt liquidity. The income is divided into 0.1% of transaction tax',
   swap198: 'Three star card: personal 3000usdt liquidity, team 100000 usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap199: 'Four star card: the individual has 4000usdt liquidity, the team has 1million usdt liquidity, set up a studio, and the income is divided into 0.1% of the transaction tax',
   swap200: 'Five star card: personal 5000usdt liquidity, team 10million usdt liquidity, set up a studio, and the income is divided into 0.1% of transaction tax',
   swap201: 'Note: all the benefits of the equity ecological card are automatically distributed on the chain. The equity card enjoys all the ecological dividends of the exchange. The equity card can be inherited, traded and transferred. If it meets the requirements, it will be automatically upgraded. If it does not meet the requirements, it will be automatically degraded and automatically withdrawn.',
   swap202: 'Benefit Card',
   swap203: 'development roadmap:',
   swap204: 'version 2.0 will be launched in July 2022 ',
   swap205: 'in September, 2022, it will be launched to dig more with coins',
   swap206: 'nft trading platform goes online in December 2022 ',
   swap207: 'goodbay game launched in June 2023 ',
   swap208: 'goodland launched in September 2023 ',
   swap209: 'D6 Swap introduction: ',
   swap210: 'sliding point:',
   swap211: 'allocation:',
   swap212: 'buy',
   swap213: 'sell',
   swap214: '0.5% LP dividend ',
   swap215: '0.5% ecological equity card dividend ',
   swap216: '0.25% reflux bottom pool destruction lp',
   swap217: '0.25% ecological construction ',
   swap218: 'No rewards to receive yet',
   swap219: 'Personal TVL',
   swap220: 'Team TVL',
   swap221: 'LP quantity',
   swap222: 'Pho in wallet',
   swap223: 'GDT',
   swap224: 'GDT air drop cumulative reward',
   swap225: 'Accumulative awards for star users',
   swap226: 'No fee countdown',
   swap227: 'Rewards can be withdrawn',
   swap228: 'LP mining reward',
   swap229: 'Pledge pho',
   swap230: 'Redemption of pho',
   swap231: 'target consensus',
   swap232: 'Consensus reached',
   swap233: 'Consensus',
   swap234: 'Consensus',
   swap235: 'Unlocked',
   swap236: 'Available',
   swap237: 'Community governance',
   swap238: 'Alliance consensus',
   swap239: 'Community Alliance',
   swap240: 'Pledge token',
   swap241: 'Pledging, please wait',
   swap242: 'transaction mining',
   swap243: 'New yesterday',
   swap244: 'Cumulative rewards for community whitelist',
   swap245: 'Cumulative rewards of equity star card',
   swap246: 'LP mining cumulative rewards',
   swap247: 'Currently available',
   swap248: 'Current recoverable amount',
   swap249: 'Trading mining',
   swap250: 'My share bonus accumulation',
   swap251: 'My transaction reward accumulation',
   swap252: 'Please enter your transaction hash',
   swap253: 'Check',
   swap254: 'Please enter your hash',
   swap255: 'Your transaction address',
   swap256: 'You will get',
   swap257: 'Only the transactions that buy GDT and create liquidity can be rewarded',
   swap258: 'Pledge gdt-pho',
   swap259: 'Redemption of gdt-pho',
   swap260: 'Total service charge of the whole network',
   swap261: 'Dividends to be claimed',
   swap262: 'GDT joint shareholders',
   swap263: 'intercourse',
   swap264: 'Mall',
   swap265: 'Selected Goods',
   swap266: 'Preferred store',
   swap267: 'Coming Soon',
   swap268: 'VGT joint shareholders',
   swap271: 'Recommended link',
   swap272: 'please bind leader',
   swap273: 'detailed',
   swap274: 'Metaverse',
   swap275: 'global',
   swap276: 'Exchange and liquidity',
   swap277: 'Trading、 Developing、 Making money、',
   swap278: 'Multi-chain aggregation DEX ecological platform',
   swap279: 'Enjoy the lowest transaction fees, profitable features and a multi-type referral program.',
   swap280: 'Route Map',
   swap281: 'Version 2.0 will be launched in July 2022',
   swap282: 'In September 2022, the currency holding and multi-mining will be opened',
   swap283: 'The NFT trading platform will be launched in December 2022',
   swap284: 'The Goodbaby game will be launched in June 2023',
   swap285: 'Goodlend will be launched in September 2023',
   swap286: 'Learn more',
   swap287: 'You have',
   swap288: 'In',
   swap289: 'Trading encrypted digital currency',
   swap290: 'Staking liquidity and tokens',
   swap291: 'Earn bonus rewards',
   swap292: 'launch pad',
   swap293: 'Authentication',
   swap294: 'Quality encrypted digital assets',
   swap295: 'Understand',
   swap296: 'Artwork',
   swap297: 'Trading market',
   swap298: 'Browse',
   swap299: 'Instant token exchange',
   swap300: 'Replicating Success',
   swap301: 'Copy not supported, this browser does not support automatic copy',
   swap302: 'Cumulative rewards for collaborative innovation',
   swap303: 'Accumulated sharing rewards',
   swap304: 'Accumulated transaction rewards',
   swap305: 'Asset package',
   swap306: 'Trading to earn coins',
   swap307: 'Withdrawable balance',
   swap308: 'Please enter the transaction hash',
   swap309: 'submit',
   swap310: 'Please enter a hash value',
   swap311: 'Successfully recorded',
   swap312: 'Binance',
   swap313: 'Platform',
   swap314: 'PHO zone',
   swap315: 'GDT zone',
   swap316: 'VGT zone',
   swap317: 'PHO star card',
   swap318: 'personal',
   swap319: 'Team',
   swap320: 'in the wallet',
   swap321: 'Select asset package',
   swap322: 'Please select',
   swap323: 'daily rate of return',
   swap324: 'income ratio',
   swap325: 'Total number due',
   swap326: 'Buy',
   swap327: 'Your wallet balance',
   swap328: 'My asset package',
   swap329: 'total income today',
   swap330: 'Today s static income',
   swap331: 'Today s dynamic income',
   swap332: 'accumulated income',
   swap333: 'expiration quantity',
   swap334: 'select',
   swap335: 'Cryptocurrency Zone',
   swap336: 'Purchase successful',
   swap337: 'Enter DApp',
   swap338: 'circle',
   swap339: 'Chat with your crypto circle buddies',
   swap340: 'candy box',
   swap341: 'A variety of high-quality asset airdrops',
   swap342: 'Come get it for free',
   swap343: 'crowd innovation pool',
   swap344: 'Mint Center',
   swap345: 'community circle',
   swap346: 'Candy box',
   swap347:'Earn',
swap348:'Airdrop',
swap349:'Sharing center',
swap350:'Affiliate program',
swap351:"Social contact",
swap352:"Bridge",
swap353:"Map",
swap354:"Water mining pool",
swap355:"Participate in the construction of mining pools and earn high incentives freely",
swap356:"Liquidity mining pool",
swap357:"Single currency pledge pool",
swap358:"Real time",
swap359:"Completed",
swap360:"Staked only",
swap361:"Sort by",
swap362:"Search",
swap363:" Popular ",
swap364:"Search pool",
swap365:"There is nothing here!",
swap366:"Startup template for the first Water Swap",
swap367:"Find the best matching path for each high-quality investor and encrypted asset",
swap368:"Create ISO",
swap369:"Become a Future Crypto Star",
swap370:"Coming soon",
swap371:"Please bind the recommender first",
swap372:"My inviter",
swap373:"Inviter's address:",
swap374:"",
swap375:"In Q3, D6 Swap was launched and launched, and Genesis coinage was launched, and the encrypted social platform CIRCLE was introduced at the same time to deeply link the ecological development of the community.",
swap376:"Q4 Inject liquidity into D6, start the “D6 Consensus Plan”, create a D6 circulation consensus, and activate the liquidity incentive plan. Launch the chain alliance cross-border mall, create a “consumption value-added” ecology, and promote a new direction for the combination of community and business.",
swap377:"",
swap378:"Q1 A package of alliance support plans to promote D6 as a basic liquidity support, increase market recognition and proportion, launch D-Pay, and promote the circulation of physical transactions of D6 Coin",
swap379:"Q2 officially launched D6 Trade, opening the era of CEX and DEX dual trading platforms, further improving the trading liquidity of the D6 market, and introducing new partners.",
swap380:"",
swap381:"",

d1:"Deposit",
d2:"Withdraw",
d3:"Transfer",
d4:"Bill",
d5:"Community",
d6:"Flash",
d7:"Feedback",
d8:"Casting",
d9:"Earn D6 Coins",
d10:"Earning up ",
d11:"Enter",
d12:"Water market",
d13:"Today's price",
d14:"Today's Earnings",
d15:"Check",
d16:"Assets",
d17:"Hide zero assets",
d18:"Currency",
d19:"Freeze",
d20:"Available",
d21:"Choose token",
d22:"Recharge amount",
d23:"Please enter the recharge amount",
d24:"Wallet balance:",
d25:"Account address",
d26:"Sure",
d27:"Recharge record",
d28:"Withdrawal amount",
d29:"Please enter the withdrawal quantity",
d30:"Account Balance:",
d31:"Arrival address",
d32:"Withdrawal record",
d33:"Transfer amount",
d34:"Please enter the transfer amount",
d35:"Enter the other party's address",
d36:"Please enter the recipient's address",
d37:"Transfer record",
d38:"Asset exchange",
d39:"To pay",
d40:"Get",
d41:"Quantity",
d42:"All",
d43:"Can be redeemed at most:",
d44:"Transaction tax:",
d45:"Due to account:",
d46:"Payment Quantity:",
d47:"Flash record",
d48:"Confirm exchange",
d49:"Flash record",
d50:"Earned",
d51:"Pending income",
d52:"Burn amount",
d53:"Please enter the burning amount",
d54:"In progress",
d55:"Over",
d56:"Circulation progress",
d57:"No records!",
d58:"Historical earnings",
d59:"My grade",
d60:"Current level",
d61:"After registration, you can become a D0 user",
d62:"Friend Invitation Program",
d63:"Invite friends to mint coins together, start the Water cognitive revolution, and accelerate rewards!",
d64:"Copy Link",
d65:"My recommender",
d66:"My valid recommendation",
d67:"Address",
d68:"Level",
d69:"Achievement",
d70:"Next level:",
d71:"Market performance",
d72:"Not completed",
d73:"Marketing department",
d74:"You are already at the highest level",
d75:"Announcement",
d76:"binding confirmation",
d77:"Leader Address:",
d78:"Confirm binding",
d79:"Cannot be negative and zero",
d80:"Insufficient balance",
d81:"Successful deposit",
d82:"Deposit failed",
d83:"Handling fee:",
d84:"Transfer in",
d85:"Transfer out",
d86:"Billing record",
d87:"Time",
d88:"Type",
d89:"Quantity",
d90:"Currency",
d91:"Please select the payment token first",
d92:"This token prohibits two-way exchange",
d93:"Cannot choose the same token",
d94:"Please bind the leader first",
d95:"Remaining burnable amount",
d96:"Please bind the recommender first",
d97:"Please fill in the recommender invitation code",
d98:"Latest Announcement",
d99:"Note:",
d100:"2: Withdrawal orders are expected to arrive on T+1 day",
d101:"1: Starting from  {min_withdraw} {token_name} for a single withdrawal",
d102:"3: 2 USDT tax is charged for each withdrawal",
d103:"Team Address",
d104:"Effective Team",
d105:"Top up today",
d106:"Pick up today",
d107:"Personal",
d108:"Team",
d109:"After it is turned on, the casting condition is met, and it will automatically participate in the casting",
d110:"It is expected to arrive within 1-2 minutes after recharging",
d111:"{feerate}% transaction tax charged for each transfer",
d112:"If you have any questions, please leave a question feedback!",
d113:"Feedback question",
d114:"Feedback list",
d115:"Feedback content",
d116:"Online customer service",
d117:"No reply yet",
d118:"Reply:",
d119:"1. Feedback",
d120:"2. Add pictures (up to 3)",
d121:"Submit",
d122:"Please upload images in png or jpg format",
d123:"Uploading, please wait...",
d124:"The file size cannot exceed 10M",
d125:"user",
d126:"CIRCLES AirDrop Mining Pool",
d127:"Total issuance",
d128:"Current output",
d129:"Extract",
d130:"My balance",
d131:"Check",
d132:"Number of people to share",
d133:"Working miner",
d134:"Hour",
d135:"Minute",
d136:"Second",
d137:"Mining",
d138:"Start mining",
d139:"Invite friends",
d140:"Friends start mining",
d141:"Get share revenue",
d142:"First level sharing",
d143:"Address",
d144:"State",
d145:"No more",
d146:"Secondary sharing",
d147:"Genesis Mining",
d148:"Enter",
d149:"CIRCLES is committed to the construction of an encrypted social privacy public chain. It is planned to be launched in 2025. The total number of public chain tokens is 2 billion. Each user can receive 1T of computing power, which will gradually decrease until a total of 80% is mined for free.",
d150:"Total asset valuation",
d151:"9·18 Pass",
d152: "The 9·18 Token is the first historical-themed community token cooperated by WaterSwap. With the goal of everyone holds it and will never forget it, we remember history and prevent dilution. Starting from the 9·18 Token! Now Get it for free!",
d153:"Mining Rules",
d154: "Each user can obtain 1T of computing power. Each T of computing power will produce 30 coins in the initial 24 hours. The computing power will decrease by 20% for every 10 million coins produced.",
d155: "50% direct invitation and 25% computing power bonus incentive for indirect invitation!",
d156: "Receive airdrop",
d157: "Withdrawal is not yet open",
d158: "When you bind the market leader, you can receive 9.18 918 token airdrops.",
d159: "If you participate in sharing, for every 10 users who share and receive airdrops, you will be eligible for another airdrop reward, up to a maximum of 918!",
d160:"My airdrop",
d161:"Inclusive Airdrop",
d162:"My sharing",
d163: "Withdrawal is not yet open",
d164:"Current level",
d165:"View earnings",
d166:"Ordinary user",
d167:"VIP Member",
d168:"Upgrade",
d169:"Copy invitation code",
d170:"address",
d171:"status",
d172:"ecology",
d173:"Hold card",
d174: "Participate in ecological construction and receive generous rewards",
d175:"Can be collected today",
d176:"C2C trading market",
d177:"Current level",
d178:"Receive",
d179:"enter",
d180:"You do not have an invitation code",
d181:"My Market",
d182:"Cannot purchase",
d183:"Your leader is not a member",
d184: "Insufficient balance on the chain",
d185: "Insufficient platform balance",
d186: "Signature failed",
d187:"Transfer failed",
d188:"Renewal",
d189:"Invitation Code",
d190:"You need to pay",
d191:"Wallet Balance",
d192:"Balance",
d193:"Platform balance",
d194: "Payment in progress...please wait",
d195:"Payment successful",
d196:"Confirm payment",
d197:"Close",
d198:"Payment failed",
d199:"Transaction",
d200:"Earn coins",
d201:"Airdrop",
d202:"community",
d203:"Ecological Center",
d204:'Select network',
d205:"pieces",
d206:"Received",
d207:"Unaccalimed"
};
