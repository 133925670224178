<template>
  <div class="kIndex">
    <img src="@/assets/imgsnowball/bgabs.png" alt="" class="bgabs">
    <img src="@/assets/imgsnowball/suger_bs.png" alt="" class="bs">
    <!-- <img src="@/assets/imgsnowball/bstext.png" alt="" class="bstext"> -->
    <div class="bstext">Airdrop Center</div>
   
    <div class="main">
            <div class="m_line1">
                <img src="@/assets/imgsnowball/CIRCLES.png" alt="" class="img">
                <div class="img">
                    <div class="i_title">CIRCLES</div>
                    <div class="i_text">{{$t('lang.d147')}}</div>
                    <div class="button" @click="tokuangchi">{{$t('lang.d148')}}</div>
                </div>
            </div>
            <div class="lin"></div>
            <div class="m_line2">{{$t('lang.d149')}}</div>
    </div>
    <div class="main">
            <div class="m_line1">
                <img src="@/assets/imgsnowball/img918.png" alt="" class="img">
                <div class="img">
                    <div class="i_title">{{$t('lang.d151')}}</div>
                    <div class="i_text">{{$t('lang.d161')}}</div>
                    <!-- <div class="button" @click="tokonto918">{{$t('lang.d148')}}</div> -->
                    <div class="button" style="opacity: 0.4;">{{$t('lang.d148')}}</div>
                </div>
            </div>
            <div class="lin"></div>
            <div class="m_line2">{{$t('lang.d152')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  methods: {
     tokuangchi (){
         this.$router.push('kuangchi')
     },
     tokonto918(){
        this.$router.push('konto918')
     }
  },
}
</script>

<style lang="less" scoped>
.kIndex{
      padding-top: 250px;
      padding-bottom: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: linear-gradient(180deg, rgba(76, 126, 249, 1) 0%, rgba(150, 208, 255, 1) 100%);
    background-position: 0 -40px;
    .bgabs{
      position: absolute;
      width: 329px;
height: 329px;
left: -10%;
top: 0;
    }
    .bs{
        width: 273px;
        height: 273px;
        position: absolute;
        right: 0;
        top: 71px;
    }
    .bstext{
        width: 145px;

 position: absolute;
 top: 84px;
 left: 20px;
 font-size: 36px;
font-weight: bold;
letter-spacing: 0px;
line-height: 43.2px;
color: rgba(255, 255, 255, 1);
    }
    .main{
      z-index: 9;
   margin-bottom: 16px;
        width: 333px;

border-radius: 16px;
opacity: 1;
background: rgba(243, 251, 254, 1);

  .m_line1{
       box-sizing: border-box;
       padding: 32px 18px;
       display: flex;
       align-items: center;
       justify-content: space-between;
       .img{
        width: 119px;
height: 119px;

        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
         .i_title{
            background: -webkit-linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
 font-size: 24px;
 font-weight: 700;
 font-family: "PingFang";
         }
         .i_text{
            color: rgba(46, 46, 46, 1);
 font-size: 18px;
 font-weight: 700;
 font-family: "PingFang";
 white-space: nowrap;
         }
         .button{
            text-align: center;
            color: #ffffff;
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
 line-height: 44px;
            width: 126px;
height: 44px;
border-radius: 10px;
opacity: 1;
background: rgba(69, 131, 255, 1);
box-shadow: 0 20px 30px 0 #191c321a;
         }
       }
  }
  .lin{
    width: 100%;
    height: 1px;
    background: rgba(46, 46, 46, 0.1);
  }
  .m_line2{
    box-sizing: border-box;
    padding: 27px 13px;
    color: rgba(46, 46, 46, 1);
 font-size: 12px;
 font-weight: 500;
 font-family: "PingFang";
  }
    }
}
</style>